import React, { useEffect, useState } from 'react';
import './Welcome.css';
import logowtext from '../assets/photos/logo-missive-green-for-welcome4.png'; 

const Welcome = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [zoom, setZoom] = useState(1); // state variable to handle zoom level

  useEffect(() => {
    setIsVisible(true);
  }, []);

  // Add event listeners on component mount and remove them on unmount
  // useEffect(() => {
  //   const handleScroll = (e) => {
  //     const deltaY = e.deltaY;
  //     if (deltaY < 0) {
  //       // Scrolling up
  //       setZoom((prevZoom) => Math.max(1, prevZoom - 0.00)); // Minimum zoom level of 1
  //     } else {
  //       // Scrolling down
  //       setZoom((prevZoom) => prevZoom + 0.00); // Increment zoom level
  //     }
  //   };

  //   window.addEventListener('wheel', handleScroll);

  //   // Cleanup
  //   return () => {
  //     window.removeEventListener('wheel', handleScroll);
  //   };
  // }, []); // Empty dependency array so this effect runs once on mount and cleanup on unmount

  return (
    <section className="welcome" id="welcome"> 
      <div className="welcome-overlay">
        <div className="welcome-text">
          <h1>דמיינו חופש, שלווה, פרטיות ואת עוצמת היצירה</h1>
          <div className="logowtext_con">
            <img  src={logowtext}  alt="מלון בוטיק המקום בגליל" />
          </div>

        </div>
      </div>
    </section>
  );
};

export default Welcome;
