import React from 'react';
import { Helmet } from 'react-helmet-async';

const DefaultMeta = () => {
    return (
        <Helmet>
            <title>המקום - מלון בוטיק בגליל | 04-9806-006</title>
            <meta name="description" content="המקום מלון בוטיק בגליל שוכן על צלע הר בסמוך למושב נטועה ומציע חופשה ייחודית בסוויטות מפוארות עם בריכה פרטית. כל סוויטה בגודל 150 מר, וכוללת בריכת שחיה פרטית מחוממת, ג`קוזי ענק והמון פינוקים" />
            <meta name="keywords" content="המקום מלון בוטיק,מלון בוטיק,מלון בוטיק בגליל,סוויטות,יוקרתי,מלון בוטיק בצפון,בריכה פרטית,בריכה מחוממת,ג`קוזי, נוף גלילי, מפואר, חדר מלון מפואר,מלון,צימר יוקרתי,סוויטה" />
            <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1, minimum-scale=1, user-scalable=no" />
            <meta property="og:image" content="https://www.hamakom-suites.co.il/AllPictures/logo-villa.png" />
            <meta property="og:title" content="המקום - מלון בוטיק בגליל | 04-9806-006" />
            <meta property="og:description" content="המקום מלון בוטיק בגליל שוכן על צלע הר בסמוך למושב נטועה ומציע חופשה ייחודית בסוויטות מפוארות עם בריכה פרטית. כל סוויטה בגודל 150 מר, וכוללת בריכת שחיה פרטית מחוממת, ג`קוזי ענק והמון פינוקים" />
        </Helmet>
    );
};

export default DefaultMeta; 