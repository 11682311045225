import React from 'react';
import { Helmet } from 'react-helmet-async';

const LuxuryVillaMeta = () => {
    return (
        <Helmet>
            <title>אחוזת הקשתות - וילה מפוארת בגליל | מלון בוטיק המקום</title>
            <meta name="description" content="אחוזת הקשתות מתאימה לאירוח עד 24 מבוגרים וילדים, כוללת בריכת גיטרה מחוממת פרטית וסגורה עם אפשרות לשחיה נגד הזרם וגקוזי ספא ענק! האחוזה כוללת 10 חדרים - 5 חדרי שינה מפוארים עם מקלחות ושירותים פרטיים בכל חדר, 3 חדרי ילדים וחדר אורחים נוסף" />
            <meta name="keywords" content="אחוזת הקשתות,וילה בגליל,וילה,וילת אירוח,וילה בצפון,המקום מלון בוטיק,מלון בוטיק,מלון בוטיק בגליל,סוויטות,יוקרתי,מלון בוטיק בצפון,בריכה פרטית,בריכה מחוממת,ג`קוזי, נוף גלילי, מפואר, חדר מלון מפואר,מלון,צימר יוקרתי" />
            <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1, minimum-scale=1, user-scalable=no" />
            <meta property="og:image" content="https://www.hamakom-suites.co.il/AllPictures/logo-villa.png" />
            <meta property="og:title" content="אחוזת הקשתות - וילה מפוארת בגליל | מלון בוטיק המקום" />
            <meta property="og:description" content="אחוזת הקשתות מתאימה לאירוח עד 24 מבוגרים וילדים, כוללת בריכת גיטרה מחוממת פרטית וסגורה עם אפשרות לשחיה נגד הזרם וגקוזי ספא ענק! האחוזה כוללת 10 חדרים - 5 חדרי שינה מפוארים עם מקלחות ושירותים פרטיים בכל חדר, 3 חדרי ילדים וחדר אורחים נוסף" />
        </Helmet>
    );
};

export default LuxuryVillaMeta;