import React, { useEffect } from "react";
import axios from 'axios';
import "./App.css";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import HamakomSuites from './components/hamakom_web';
import LuxurySuites from './components/LuxurySuites'; 
import LuxuryVips from './components/LuxuryVips'; 
import VirtualTour   from './components/VirtualTour'; 
import LuxuryVilla   from './components/LuxuryVilla'; 
import LuxuryDomi   from './components/LuxuryDomi'; 

import Negishot   from './components/Negishot'; 
import Tos   from './components/Tos'; 
import Kosher   from './components/Kosher'; 
import Restaurants   from './components/Restaurants'; 



import ChatApp from './components/gpt';
import GetEmbeddings from './components/get_embeddings';


function App() {

    // Microsoft Clarity Insert at the top of your React entry file
  const script = document.createElement("script");
  script.type = "text/javascript";
  script.async = true;
  script.innerHTML = `
    (function(c,l,a,r,i,t,y){
      c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
      t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
      y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
  })(window, document, "clarity", "script", "j3m7vz6stj");
  `;
  document.head.appendChild(script);

  useEffect(() => {
    const session_id = sessionStorage.getItem("session_id");
    if (!session_id) {
      const sessid = async () => {
        try {
            console.log("vdd");
            var domaino;
            if (process.env.NODE_ENV === 'development') {
               domaino = 'http://localhost:8080/chatapp/get_session/';
            } else {
               domaino = 'https://polls-service-6qrvcljmeq-uc.a.run.app/chatapp/get_session/';
            }
            const response_session = await axios.get(domaino);
            const session_id = response_session.data.session_id;
            sessionStorage.setItem("session_id", session_id);
        } catch (error) {
          console.error("Error getting session ID:", error);
        }
      };
      sessid();
    }
  }, []);

  return (
    <HelmetProvider>
      <Router>
        <div className="App">
          {/* Add any head`er, footer, or other shared components here */}
          {/* <header className="App-header">
          <h1>ChatGPT Chat App</h1>
          </header>*/}
          <meta name="viewport" content="width=device-width, initial-scale=1"></meta>
          <Routes>
            <Route path="/gpt" element={<ChatApp />} />
            <Route path="/" element={<HamakomSuites />} />
            <Route path="/LuxurySuites" element={<LuxurySuites />} />
            <Route path="/LuxuryVips" element={<LuxuryVips />} />
            <Route path="/LuxuryVilla" element={<LuxuryVilla />} />
            <Route path="/LuxuryDomi" element={<LuxuryDomi />} />

            <Route path="/Negishot" element={<Negishot />} />
            <Route path="/Tos" element={<Tos />} />
            <Route path="/Kosher" element={<Kosher />} />
            <Route path="/Restaurants" element={<Restaurants />} />

            <Route path="/VirtualTour" element={<VirtualTour />} />
            <Route path="/get-embeddings-model" element={<GetEmbeddings />} />
          </Routes>
        </div>
      </Router>
    </HelmetProvider>
  );
}

export default App;
