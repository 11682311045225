import React, { useState, useEffect } from 'react';
import './hamakom_web.css';
//import './App.css';
import { FaArrowUp } from 'react-icons/fa';
import DefaultMeta from './DefaultMeta';


// Components
//import Header from './components/Header';
import NavigationBar from './NavigationBar';
import Welcome from './Welcome';
import Introduction from './Introduction';
import Intro2nd from './Intro2nd';
//import ImageGallery from './ImageGallery';
//import CallToAction from './CallToAction';
//import Accommodations from './Accommodations';
//import Services from './Services';
//import Recommendations from './Recommendations';
//import Contact from './Contact';
import FloatingButton from './FloatingButton';
import Gallery from './Gallery';
import FeaturesIcons from './FeaturesIcons';
import SuiteType from './SuiteType';

import Footer from './footer';

import { animateScroll as scroll } from 'react-scroll';

const HamakomSuites = () => {
  const [scrollPosition, setScrollPosition] = useState(0);
  const [bgColor, setBgColor] = useState('white');
  const [activeDiv, setActiveDiv] = useState(null);
  const [hasScrolled, setHasScrolled] = useState(false);

  //scroll arrows mark down for homepage
  const handleScroll = () => {
    if (window.scrollY > 0) {
      setHasScrolled(true);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);


  //this is a temp patch for the observer loop annoying issue:
  useEffect(() => {
    window.addEventListener('error', e => {
      if (e.message === 'ResizeObserver loop limit exceeded' || e.message === 'Script error.') {
        const resizeObserverErrDiv = document.getElementById(
          'webpack-dev-server-client-overlay-div'
        )
        const resizeObserverErr = document.getElementById(
          'webpack-dev-server-client-overlay'
        )
        if (resizeObserverErr) {
          resizeObserverErr.setAttribute('style', 'display: none');
        }
        if (resizeObserverErrDiv) {
          resizeObserverErrDiv.setAttribute('style', 'display: none');
        }
      }
    })
  }, [])
  useEffect(() => {
    if (activeDiv) {
      const script = document.createElement('script');
      script.src = 'https://bookingresults.com/Silverbyte.cdn/searchbox/react/1.6.3.0/searchbox.js';
      script.async = true;
      document.body.appendChild(script);

      return () => {
        document.body.removeChild(script);
      };
    }
    
  }, [activeDiv]);

  const handleScrollToTop = () => {
    scroll.scrollToTop({
      duration: 500,
      smooth: 'easeInOutQuint',
    });
  };

  return (
    <>
      <DefaultMeta />
      <div className="App">
       
        <link rel="stylesheet" href="https://bookingresults.com/Silverbyte.cdn/searchbox/react/1.6.3.0/searchbox.css" />
        <link rel="stylesheet" href="https://bookingresults.com/2020.hamakom-suites/Content/new_be/SBoxCustomTheme.css" />
        {!hasScrolled && (
          <div id="arrow-container">
              <svg class="arrows">
							<path class="a1" d="M0 0 L30 32 L60 0"></path>
							<path class="a2" d="M0 20 L30 52 L60 20"></path>
							<path class="a3" d="M0 40 L30 72 L60 40"></path>
						</svg>
          </div>
        )}
    {/*       <Header scrollPosition={scrollPosition} /> */}
        <Welcome scrollPosition={scrollPosition} />
        <NavigationBar bgColor={bgColor} activeDiv={activeDiv} setActiveDiv={setActiveDiv} />
        <Introduction />
        <Intro2nd />
        <FeaturesIcons />
        <Gallery />
        <SuiteType setBgColor={setBgColor} />
        <Footer />

    {/*
    //      <ImageGallery />
    //      <CallToAction />
    //      <Accommodations />
    //      <Services />
    //      <Recommendations />
    //      <Contact />  */}
          <FloatingButton activeDiv={activeDiv} setActiveDiv={setActiveDiv}/>

          <button className="scroll-top-button" onClick={handleScrollToTop}><FaArrowUp /></button>

      </div>
    </>
  );
}

export default HamakomSuites;